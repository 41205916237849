import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalContact from '@solid-ui-blocks/Modal/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import Content from '@solid-ui-blocks/Content/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'
import theme from '../_theme'

const TermsPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Terms & Conditions' />
      
      {/* Modals */}      
      <ModalContact content={content['contact']} />

      <Header content={content['header-logo']} />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} reverse />
      </Container>
      <Divider space='3' /> 
      
           
      <Content content={content['content-1']}  />      
      <Content content={content['content-2']}  /> 
      <Content content={content['content-3']}  />   
      <Content content={content['content-4']}  />   
      <Content content={content['content-5']}  />   
      <Content content={content['content-6']}  />   
      <Content content={content['content-7']}  />   
      <Content content={content['content-8']}  />   
      <Content content={content['content-9']}  />   
      <Content content={content['content-10']}  />   
      <Content content={content['content-11']}  />   
      <Content content={content['content-12']}  />   
      <Content content={content['content-13']}  /> 
      <Footer content={content['footer-logo']} />
    </Layout>
  )
}

export const query = graphql`
  query TermsBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/terms", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default TermsPage
